import { MapContainer, Popup, TileLayer, useMapEvent } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useValues } from '../contexts/AppContext';
import DrawShape from './DrawShape';
import { useState } from 'react';

const Map = () => {
    const { shape, center } = useValues();
    const [mapRef, setMapRef] = useState(null);
    const MAPBOX_ACCESS_TOKEN = "pk.eyJ1IjoiZW1tYW4tYm9pa2EiLCJhIjoiY2x0eWY2dGdoMGZsZzJtcWZvbDNsaWxpNiJ9.kYTmUT5nN8YHGPplN6P7Cw";
    return (
        <MapContainer
            center={center} zoom={18} style={{ height: '100%', width: '100vw' }}
            onContextMenu={e => console.log(e)}
            ref={setMapRef}
        >
            <TileLayer
                // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                url={`https://api.mapbox.com/styles/v1/mapbox/outdoors-v12/tiles/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}`}
                tileSize={512}
                zoomOffset={-1}
                maxZoom={23}
                accessToken={MAPBOX_ACCESS_TOKEN}
            />
            {
                shape?.length > 0 &&
                <DrawShape />
            }
            <CustomContextMenu mapRef={mapRef} />
        </MapContainer>
    );
}

const CustomContextMenu = ({ mapRef }) => {
    const [contextMenuPosition, setContextMenuPosition] = useState(null);
    const { setOrigin, setDestination } = useValues();
    // eslint-disable-next-line
    const map = useMapEvent({
        'contextmenu': (e) => {
            setContextMenuPosition(e?.latlng);
        }
    })
    const handleClickOrigin = () => {
        setOrigin(`${contextMenuPosition.lat},${contextMenuPosition.lng}`);
        mapRef && mapRef.closePopup();
    }
    const handleClickDestination = () => {
        setDestination(`${contextMenuPosition.lat},${contextMenuPosition.lng}`);
        mapRef && mapRef.closePopup();
    }
    return (
        <>
            {
                contextMenuPosition &&
                <Popup position={contextMenuPosition}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px'
                    }}>
                        <button className='btn btn-danger' onClick={handleClickOrigin}>Route from this point</button>
                        <button className='btn btn-primary' onClick={handleClickDestination}>Route to this point</button>
                    </div>
                </Popup>
            }
        </>
    );
};

export default Map;